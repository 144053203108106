import React from 'react';
import PropTypes from 'prop-types';

import LoginForm from 'containers/LoginForm';
import Layout, { Layouts } from 'containers/Layout';
import { withTranslation } from 'utils/with-i18next';

export class LoginPage extends React.PureComponent {
  render() {
    const { t } = this.props;

    return (
      <Layout type={Layouts.AUTH}>
        <LoginForm t={t} />
      </Layout>
    );
  }
}

LoginPage.propTypes = {
  t: PropTypes.func,
};

LoginPage.getInitialProps = async () => ({
  namespacesRequired: ['common', 'banner', 'features'],
});

export default withTranslation('common')(LoginPage);
